import HostManager from './HostManager';

export function getImageUrl(logo) {
  if (!logo) {
    return 'default-image-url.jpg'; // 替换为默认图片的 URL
  }
  if (logo.startsWith('http://') || logo.startsWith('https://')) {
    return logo;
  }
  return `${HostManager.getImageHost()}${logo}`;
}
