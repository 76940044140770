import axios from 'axios';

class HttpClient {
  async get(url, config = {}) {
    console.log(`Sending GET request to: ${url}`);
    try {
      const response = await axios.get(url, config);
      console.log('GET response:', response);
      return response.data;
    } catch (error) {
      console.error('GET request error:', error);
      throw error;
    }
  }

  async post(url, data, config = {}) {
    console.log(`Sending POST request to: ${url} with data:`, data);
    try {
      const response = await axios.post(url, data, config);
      console.log('POST response:', response);
      return response.data;
    } catch (error) {
      console.error('POST request error:', error);
      throw error;
    }
  }

  // 可以根据需要添加更多方法，如 put, delete 等
}

export default new HttpClient();
