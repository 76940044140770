class HostManager {
  constructor() {
    this.baseUrl = 'https://api.quanguanjia.xyz/qunguanjia-web-user';
    this.imageHost = 'http://image.quanguanjia.xyz/';
  }

  getBaseUrl() {
    return this.baseUrl;
  }

  getImageHost() {
    return this.imageHost;
  }
}

export default new HostManager();
