<template>
  <div class="group-detail">
    <button @click="goBack" class="back-button">返回</button>
    <img :src="groupImage" alt="Group Image" class="group-image" />
    <h1 class="group-title">{{ groupName }}</h1>
    <p class="group-description">{{ groupDescription }}</p>
    <div class="qr-code">
      <h3 class="qr-title">群二维码</h3>
      <canvas ref="qrCanvas"></canvas>
    </div>
    
  </div>
</template>

<script>
import { getImageUrl } from '../utils';
import QRCode from 'qrcode';

export default {
  data() {
    return {
      groupImage: '',
      groupName: '',
      groupDescription: '',
      praisedQrUrl: '',
      comments: [
        { author: 'CW', text: '生活不易，且工作且珍惜' }
      ]
    };
  },
  created() {
    this.loadGroupDetails();
  },
  mounted() {
    this.generateQRCode();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    loadGroupDetails() {
      const { groupLogo, groupName, groupDesc, praisedQrUrl } = this.$route.query;
      this.groupImage = getImageUrl(groupLogo);
      this.groupName = groupName || '未知群组';
      this.groupDescription = groupDesc || '暂无描述';
      this.praisedQrUrl = praisedQrUrl;
    },
    generateQRCode() {
      if (this.praisedQrUrl) {
        QRCode.toCanvas(this.$refs.qrCanvas, this.praisedQrUrl, function (error) {
          if (error) console.error(error);
        });
      }
    }
  }
};
</script>

<style>
.group-detail {
  padding: 10px;
}

.back-button {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
}

.group-image {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.group-title {
  font-size: 20px;
  margin: 0 0 5px 0;
}

.group-description {
  margin-bottom: 10px;
}

.qr-code {
  margin-bottom: 20px;
  text-align: center; /* 仅居中二维码 */
}

.qr-title {
  text-align: left; /* 左对齐标题 */
}

.comments {
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.comment-input {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #ccc;
}
</style>
