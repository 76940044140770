import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/Home.vue';
import GroupDetail from '../components/GroupDetail.vue';

const routes = [
  { path: '/', component: HomePage, name: 'HomePage' },
  { path: '/group-detail', component: GroupDetail, name: 'GroupDetail' }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
