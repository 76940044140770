<template>
  <div :class="appClass" @scroll="handleScroll">
    <input
      type="text"
      v-model="searchKeyword"
      @input="handleInput"
      placeholder="搜索群组"
      class="search-bar"
    />
    <div
      v-if="refreshing"
      class="refresh-indicator"
    >
      正在刷新...
    </div>
    <div
      v-for="(group, index) in groups"
      :key="index"
      class="group"
      @click="goToDetail(group)"
    >
      <div class="image-container">
        <img :src="getImageUrl(group.groupLogo)" alt="Group Logo" />
      </div>
      <h2 class="group-name">{{ group.groupName }}</h2>
      <p class="group-desc">{{ group.groupDesc }}</p>
    </div>
    <div
      v-if="loading"
      class="loading-indicator"
    >
      加载中...
    </div>
    <footer v-if="isPC" class="footer">
      沪ICP备2023009307号-1
    </footer>
  </div>
</template>

<script>
import HttpClient from '../HttpClient';
import PathManager from '../PathManager';
import { getImageUrl } from '../utils'; // 确保导入

export default {
  name: 'HomePage',
  data() {
    return {
      groups: [],
      pageNum: 1,
      loading: false,
      refreshing: false,
      searchKeyword: '',
      isPC: false
    };
  },
  computed: {
    appClass() {
      return this.isPC ? 'app-pc' : 'app-mobile';
    }
  },
  created() {
    this.detectDevice();
    this.fetchGroups();
  },
  methods: {
    detectDevice() {
      const userAgent = navigator.userAgent;
      this.isPC = !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    },
    async fetchGroups(refresh = false) {
      if (this.loading) return;
      this.loading = true;
      if (refresh) {
        this.pageNum = 1;
        this.refreshing = true;
      }

      console.log('Fetching group list with keyword:', this.searchKeyword);
      try {
        const data = await HttpClient.get(PathManager.getGroupListPage(), {
          params: { pageNum: this.pageNum, keyword: this.searchKeyword }
        });
        console.log('Fetched groups:', data.data);
        if (refresh) {
          this.groups = data.data;
        } else {
          this.groups = [...this.groups, ...data.data];
        }
        this.pageNum++;
      } catch (error) {
        console.error('Error fetching groups:', error);
      } finally {
        this.loading = false;
        if (refresh) this.refreshing = false;
      }
    },
    handleInput() {
      this.pageNum = 1;
      this.fetchGroups(true);
    },
    handleScroll(event) {
      const top = event.target.scrollTop === 0;
      const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
      if (top && !this.refreshing) {
        this.fetchGroups(true);
      }
      if (bottom && !this.loading) {
        this.fetchGroups();
      }
    },
    goToDetail(group) {
      this.$router.push({
        name: 'GroupDetail',
        query: {
          id: group.groupId,
          groupLogo: group.groupLogo,
          groupName: group.groupName,
          groupDesc: group.groupDesc,
          praisedQrUrl: group.praisedQrUrl
        }
      });
    },
    getImageUrl // 确保在 methods 中定义
  }
}
</script>


<style>
.app-pc {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow-y: auto;
  height: 100vh;
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
}

.app-mobile {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow-y: auto;
  height: 100vh;
  padding: 10px;
}

.search-bar {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  border: 1px solid #ccc;
}

.refresh-indicator,
.loading-indicator {
  text-align: center;
  padding: 10px;
  color: #42b983;
}

.group {
  margin-bottom: 30px;
  text-align: left;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  cursor: pointer;
}

.group-name {
  margin: 10px 0 5px 0; /* 减少底部间距 */
  font-size: 1.2em;
}

.group-desc {
  margin: 0 0 5px 0; /* 减少底部间距 */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  font-size: 0.8em;
  -webkit-box-orient: vertical;
}

.image-container {
  width: 100%;
  padding-top: 37.5%;
  position: relative;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.footer {
  text-align: center;
  padding: 10px;
  color: #888;
}
</style>
